<style>
    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1000;

        width: 250px;

        padding: 0.25rem;
        border-radius: 0.25rem;

        border: 1px solid #CCCCCC;
        background: #FFFFFF;
    }

    .overlay ul {
        list-style: none;
        padding: 0 0 0 0.5em;
        margin: 0;
    }

    .overlay ul li {
        font-size: 0.9rem;
    }

    .player-list {
        color: #999999;
    }

    .player-list .answered {
        color: #000000;
    }

    .player-list .score-update {
        color: #31a031;
        font-weight: bold;
    }

    .hide-toggle {
        display: block;
        text-align: right;
    }

    .hide-toggle span {
        cursor: pointer;
    }

    .question-preview img {
        max-width: 250px;
        max-height: 250px;
        object-fit: contain;

        cursor: zoom-in;
    }

    .full-image {
        position: fixed;
        top: 0;
        left: 0;

        cursor: zoom-out;
    }

    .full-image img {
        width: 100vw;
        height: 100vh;
        object-fit: contain;
    }

    .easter-egg {
        display: block;
        font-size: 3px;
    }
</style>
<script>
    import { game } from "./game";
    import { postRemote } from "./utils";

    let hidden = false;
    let enlarged = false;

    function toggleHide() {
        hidden = !hidden;
    }

    function toggleEnlarge() {
        enlarged = !enlarged;
    }

    async function adminNextQuestion() {
        try {
            await postRemote("/api/next-question");
        } catch (err) {
            alert(err);
        }
    }

    async function adminShowAnswers() {
        try {
            await postRemote("/api/show-answers");
        } catch (err) {
            alert(err);
        }
    }

    async function adminReset() {
        try {
            await postRemote("/api/reset");
        } catch (err) {
            alert(err);
        }
    }
</script>

<div class="overlay">
    <div class="hide-toggle">
        <span class="badge bg-secondary" on:click={toggleHide}>
        {#if hidden}
            Show
        {:else}
            Hide
        {/if}
        </span>
    </div>

    {#if $game.server.question && enlarged}
        <div class="full-image" on:click={toggleEnlarge}>
            <img alt="Where is this taken?" src={$game.server.question.image}>
        </div>
    {/if}

    {#if !hidden}
        {#if $game.server.question}
            <div class="question-preview" on:click={toggleEnlarge}>
                <p>
                    <strong>Where is that picture taken?</strong>
                    Mark the position on the map
                </p>
                <img alt="Where is this taken?" src={$game.server.question.image}>
                <p>
                    (click on photo to enlarge)
                    <span class="easter-egg">Disclaimer: Does not work with body parts</span>
                </p>
            </div>
        {/if}

        {#if $game.server.players}
            <div>
                All players:

                <ul class="player-list">
                    {#each $game.server.players as player}
                        <li class:answered="{player.hasAnswer}">
                            {player.username}
                            {#if player.username == $game.username}
                                <strong>(me)</strong>
                            {/if}
                            {player.score}
                            {#if player.scoreUpdate > 0}
                                <span class="score-update">+{player.scoreUpdate}</span>
                            {/if}
                        </li>
                    {/each}
                </ul>
            </div>
        {/if}

        {#if $game.username == "admin"}
            <div class="d-grid gap-2">
                {#if $game.server.canAnswer}
                    <button class="btn btn-primary" type="button" on:click={adminShowAnswers}>Show answers</button>
                {:else}
                    <button class="btn btn-primary" type="button" on:click={adminNextQuestion}>Next question</button>
                {/if}
                <button class="btn btn-danger" type="button" on:click={adminReset}>Reset</button>
            </div>
        {/if}
    {/if}
</div>
