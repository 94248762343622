<script>
    import { game } from "./game";
    import { onMount, onDestroy } from "svelte";
    import GameOverlay from "./GameOverlay.svelte";

    let map = undefined;
    let playerMarker = undefined;
    const markers = new Set;

    let gameRef = undefined;
    let unsubscribeGame = undefined;

    let canClear = true;

    onMount(async () => {
        unsubscribeGame = game.subscribe(value => {
            gameRef = value;

            if (gameRef.server.answers !== undefined && gameRef.server.solution !== undefined) {
                revealSolution(gameRef.server.answers, gameRef.server.solution);
                canClear = true;
            } else if (gameRef.server.question) {
                if (canClear) clear();
                canClear = false;
            }
        });

        mapboxgl.accessToken = "pk.eyJ1Ijoicm9hZHJ1bm5lcjY2IiwiYSI6ImNraWl0ZDI1dzA5aXIycnF1dGpmMHhsYWgifQ.H9MI4uZMOKe6QqK7wLKkwg";
        map = new mapboxgl.Map({
            container: "mapboxMap",
            style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
            center: [0, 0], // starting position [lng, lat]
            zoom: -1 // starting zoom
        });

        map.on("click", e => {
            setPlayerMarker(e.lngLat);
        });
    });

    onDestroy(() => {
        unsubscribeGame();
    })

    function clear() {
        clearAllMarkers();
        map.easeTo({
            center: [0, 0],
            zoom: 0,
        });
    }

    function setPlayerMarker(lngLat) {
        if (!gameRef.server.canAnswer) {
            return;
        }

        game.giveAnswer(lngLat);
        clearPlayerMarker();
        playerMarker = createMarker(lngLat);
    }

    function clearPlayerMarker() {
        if (playerMarker !== undefined) {
            playerMarker.remove();
            markers.delete(playerMarker);
            playerMarker = undefined;
        }
    }

    function createMarker(lngLat, options) {
        options = options || {};

        const marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(map);
        markers.add(marker);

        if (options.label !== undefined) {
            const label = document.createElement("div");
            label.className = "marker-solution";
            label.textContent = options.label;
            marker.getElement().appendChild(label);
        }

        return marker;
    }

    function clearAllMarkers() {
        clearPlayerMarker();

        markers.forEach(marker => {
            marker.remove();
        });
        markers.clear();
    }

    function revealSolution(playerAnswers, solution) {
        clearAllMarkers();

        const answerLngLat = new mapboxgl.LngLat(
            solution.lngLat.lng,
            solution.lngLat.lat,
        );

        playerAnswers.forEach(answer => {
            const ownMarker = answer.player == gameRef.username;
            const color = ownMarker ? undefined : "#888888";

            const distance = formatDistance(answerLngLat.distanceTo(answer.lngLat));

            createMarker(answer.lngLat, { color, label: `${answer.player} ${distance}` });
        });
        
        createMarker(solution.lngLat, { color: "#00FF00" });

        fitCoordinates([
            solution.lngLat,
            ...playerAnswers.map(answer => answer.lngLat),
        ]);
    }

    function fitCoordinates(coordinates) {
        const lng = coordinates.map(lngLat => lngLat.lng);
        const lat = coordinates.map(lngLat => lngLat.lat);

        const bounds = new mapboxgl.LngLatBounds(
            new mapboxgl.LngLat(Math.min(...lng), Math.min(...lat)),
            new mapboxgl.LngLat(Math.max(...lng), Math.max(...lat)),
        );

        map.fitBounds(bounds, { padding: {
            top: 100,
            right: 350,
            bottom: 100,
            left: 100,
        } });
    }

    function formatDistance(meters) {
        if (meters > 10_000) {
            return Math.round(meters / 1000) + "km";
        }

        return `${Math.round(meters * 100) / 100}m`;
    }
</script>
<style>
    .game, .map {
        width: 100%;
        height: 100%;
    }

    .canAnswer :global(.mapboxgl-canvas-container.mapboxgl-interactive) {
        cursor: pointer !important;
    }
</style>

<div class="game">
    <GameOverlay></GameOverlay>
    <div class:canAnswer="{$game.server.canAnswer}" id="mapboxMap" class="map"></div>
</div>
