export function getRemote(url) {
    return fetch(url)
    .then(response => response.json())
    .then(data => {
        if (data.error) {
            throw data.error;
        }

        return data;
    });
}

export function postRemote(url, data) {
    return fetch(
        url,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }
    )
    .then(response => response.json())
    .then(data => {
        if (data.error) {
            throw data.error;
        }

        return data;
    });
}


export function objectsEqual(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
}
