<script>
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    let username = "";

    function enterGame() {
        dispatch("enterGame", {
            username: username
        });
    }
</script>
<style>
    .form {
        background: #CCCCCC;
        border-radius: 0.25rem;
        padding: 0.25rem;

        color: #333333;
        font-weight: bold;
        font-size: 1.2rem;
    }

    .flex-box {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 100%;
    }
</style>

<div class="container flex-box">
    <div class="form">
        <form on:submit={enterGame}>
            <div class="d-grid gap-2">
                <input id="username" type="text" placeholder="Username" class="form-control" bind:value={username}>
                <button class="btn btn-primary" type="submit">Join Game</button>
            </div>
        </form>
    </div>
</div>
