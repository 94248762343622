import { getRemote, objectsEqual, postRemote } from "./utils";
import { writable } from 'svelte/store';

const defaultState = {
    connected: false,
    connecting: false,
    server: {},
};

const refreshRate = 2000;

function createGame() {
	const { subscribe, set, update } = writable(Object.assign({}, defaultState));

    let gameRef;

    subscribe((value) => {
        gameRef = value;
    });

    function setFields(fields) {
        const newState = Object.assign(Object.assign({}, gameRef), fields);

        if (!objectsEqual(gameRef, newState)) {
            set(newState);
        }
    }

    async function connect(options) {
        update(state => {
            if (state.connected) {
                throw "Already connected";
            }

            return Object.assign(state, {
                connecting: true
            })
        });

        const username = options.username;

        try {
            await postRemote("/api/join", {
                username: username
            });
        } catch(err) {
            setFields({
                connecting: false
            });
            throw err;
        }

        setFields({
            connected: true,
            username: username
        });

        startRefresh();
    }
    
    function disconnect() {
        setFields({
            connected: false,
            connecting: false
        });
    }

    let refreshInterval = undefined;

    function startRefresh() {
        async function refresh() {
            let status;

            const url = "/api/status?" + new URLSearchParams({
                username: gameRef.username
            }).toString();

            try {
                status = await getRemote(url);
            } catch (err) {
                alert(err);
                reset();
                return;
            }

            setFields({ server: status });
        }

        refreshInterval = setInterval(refresh, refreshRate);
        refresh();
    }

    function stopRefresh() {
        if (refreshInterval !== undefined) {
            clearInterval(refreshInterval);
            refreshInterval = undefined;
        }
    }

    function reset() {
        stopRefresh();
        set(Object.assign({}, defaultState));
    }

    async function giveAnswer(lngLat) {
        try {
            const data = await postRemote("/api/give-answer", {
                username: gameRef.username,
                lngLat: lngLat,
            });

            if (data.warning) {
                alert(data.warning);
            }
        } catch (err) {
            alert(err);
            reset();
            return;
        }
    }

	return {
		subscribe,
		connect,
        disconnect,
        reset,
        giveAnswer,
	};
}

export const game = createGame();
