<script>
    import { game } from "./game";
    import ConnectForm from "./ConnectForm.svelte";
    import GameField from "./GameField.svelte";

    async function enterGame(event) {
        try {
            await game.connect(event.detail);
        } catch(err) {
            alert(err);
        }
    }
</script>

{#if !$game.connecting && !$game.connected}
    <ConnectForm on:enterGame={enterGame}></ConnectForm>
{/if}

{#if $game.connected}
    <GameField></GameField>
{/if}
